import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { RootPageLayout, Disclosures } from '../components'

import DarkLogo from '../images/milliman_logo_dark.svg'
import CapGroupLogo from '../images/cap_group_logo.png'

const IndexPage = () => {
  if (process.env.GATSBY_CAPGROUP_FUNDS !== 'true')
    return null;

  return <RootPageLayout id="home" className="page-index" showNav={process.env.GATSBY_CLIENT === 'milliman'}>

    <div className="header-image"></div>

    <Container className="lead">
      <Row className="justify-content-center">
        <Col xs={10}>
          <h1>
            {process.env.GATSBY_CLIENT === 'milliman' ? <>Structured Investment Funds</> : <>Structured Investment Rate Tool</>}
          </h1>
          <p className="mb-0">
            {process.env.GATSBY_CLIENT === 'milliman' ? <>
              Milliman FRM's Structured Investing Strategies and Funds are featured variable investment options within Advanced Outcomes Annuity<sup>&#174;</sup>.
              This variable annuity is intended as a long-term retirement savings vehicle for a portion of client portfolios.
            </> : <>
              The structured investing strategies and funds detailed here are featured subaccounts within Advanced Outcomes Annuity<sup>&#174;</sup>.
              This variable annuity issued by  American General Life Insurance Company (AGL), a subsidiary of Corebridge Financial, Inc.,
              is intended as a long-term retirement savings vehicle for client portfolios.
            </>}
          </p>
        </Col>
      </Row>
    </Container>
  
    <div className="series">
      <Container>
        <Row className="justify-content-center align-items-stretch">
          {/* <Col xs={10} lg={5} className="my-3 justify-content-stretch">
            
              <Card className="h-100">
                <Card.Body>
                  <img className="mb-4" src={DarkLogo} alt="" style={{height: '2rem'}} />
                  <h4 className="mt-2 mb-3">
                    Structured Outcome Funds
                  </h4>
                  <p>
                    {process.env.GATSBY_CLIENT === 'milliman' ?
                      <>
                        Milliman FRM's Structured Outcome-based Strategies and Funds are featured variable investment options within Advanced Outcomes Annuity<sup>&#174;</sup>.
                        This variable annuity is intended as a long-term retirement savings vehicle for a portion of client portfolios.
                      </> : <>
                        The structured outcome funds managed by Milliman Financial Risk Management offer growth potential while seeking a level of targeted downside protection.
                        Using a passive approach, the funds include defined parameters for growth potential and targeted downside protection.
                      </>
                    }
                  </p>
                  <Link className="btn btn-primary" to="/overview">View {process.env.GATSBY_CLIENT === 'milliman' ? 'Funds' : 'Rates'}</Link>
                </Card.Body>
              </Card>
            
          </Col> */}

          <Col xs={10} lg={5} className="my-3 justify-content-stretch">
            <Card className="h-100">
              <Card.Body>
                <img className="mb-4" src={DarkLogo} alt="" style={{height: '2rem'}} />
                <img className="ms-3 ps-3 mb-4" src={CapGroupLogo} alt="" style={{height: '2rem', borderLeft: '#aaa 1px solid'}} />
                <h4 className="mt-2 mb-3">
                  Hedged Equity Funds
                </h4>
                <p>
                  {process.env.GATSBY_CLIENT === 'milliman' ?
                    <>
                      Milliman FRM's Hedged Equity Strategies and Funds are featured variable investment options within Advanced Outcomes Annuity<sup>&#174;</sup>.
                      This variable annuity is intended as a long-term retirement savings vehicle for a portion of client portfolios.
                    </> : <>
                      The hedged equity funds are managed by Milliman Financial Risk Management and sub-advised by Capital Group<sup>&#174;</sup>, home of American Funds<sup>&#174;</sup>.
                      As actively managed funds, these strategies offer you access to a diverse portfolio of U.S.-based equities with some protection from portfolio underperformance.
                    </>
                  }
                </p>
                <Link className="btn btn-primary" to="/capgroup/overview">View {process.env.GATSBY_CLIENT === 'milliman' ? 'Funds' : 'Rates'}</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row className="justify-content-center">
        <Col xs={10}>
          <Disclosures />
        </Col>
      </Row>
    </Container>

  </RootPageLayout>
}

export default IndexPage
